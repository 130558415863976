import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import people from '@/services/api/people'

function Activate({ person, onActivate }) {
  const [loading, setLoading] = useState(false)

  const onConfirm = useCallback(async () => {
    try {
      setLoading(true)
      await people.activateAccount(person.id)
      toast.success('Conta reativada com sucesso')
      onActivate()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }, [onActivate, person.id])

  const onPress = () => {
    confirmAlert({
      title: 'Reativar a conta',
      message: 'Você está certo que deseja reativar esta conta?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-success',
          onClick: onConfirm,
        },
      ],
    })
  }

  if (!person.deleted) return null

  return (
    <div className="mr-2">
      <a onClick={onPress} aria-hidden="true" disabled={loading} className={`btn btn-success btn-sm ${loading ? 'loading' : ''}`}>
        <i className="fas fa-undo" />
        &nbsp;Reativar conta
      </a>
    </div>
  )
}

Activate.propTypes = {
  person: PropTypes.objectOf(Object).isRequired,
  onActivate: PropTypes.func.isRequired,
}

export default Activate
