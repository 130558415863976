import {
  Avatar, Card, ErrorBox, Flexbox,
} from '@/components'
import { ADOPTION_STATUS } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import adoptionsService from '@/services/api/adoptions'

export default function AdoptionsCard({ adoptions, onReverted }) {
  const [revertingId, setRevertingId] = useState(null)

  const onRevert = useCallback(async (adoption) => {
    try {
      setRevertingId(adoption.id)
      const { data } = await adoptionsService.revert(adoption.id)
      onReverted(data)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setRevertingId(null)
    }
  }, [onReverted])

  const onPressRevert = useCallback((adoption) => {
    confirmAlert({
      title: 'Reverter adoção',
      message: `Você está certo que deseja reverter a adoção? A tutoria do animal voltará para ${adoption?.donor?.fullName}?`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-error',
          onClick: () => onRevert(adoption),
        },
      ],
    })
  }, [onRevert])

  if (!adoptions) return null

  return (
    <Card.Base style={{ maxHeight: 500, overflowY: 'auto' }}>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <i className="fa-lg fas fa-heart" />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">
              Adoções <small>({adoptions.length} {adoptions.length === 1 ? 'adoção' : 'adoções'})</small>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          adoptions?.length
            ? (
              <table className="table text-small">
                <thead>
                  <tr>
                    <th width="80px">#</th>
                    <th>Doador</th>
                    <th>Novo tutor</th>
                    <th>Status</th>
                    <th>Data</th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    adoptions.map((adoption) => (
                      <tr key={adoption.id}>
                        <td>{adoption.id}</td>
                        <td>
                          <Avatar avatarSize="sm" name={adoption?.donor?.fullName} photoURL={adoption?.donor?.photoURL} />
                          <span>&nbsp;{adoption.donor.fullName}</span>
                        </td>
                        <td>
                          <Avatar avatarSize="sm" name={adoption?.newTutor?.fullName} photoURL={adoption?.newTutor?.photoURL} />
                          <span>&nbsp;{adoption.newTutor.fullName}</span>
                        </td>
                        <td className={ADOPTION_STATUS.class[adoption.status]}>
                          {ADOPTION_STATUS.t(adoption.status)}
                        </td>
                        <td>{dateMask(adoption.createdAt, { minute: '2-digit', hour: '2-digit' })}</td>
                        <td className="text-right" width="130">
                          {
                            adoption.status === ADOPTION_STATUS.enum.CONFIRMED && (
                              <a
                                className={`btn btn-error btn-action btn-sm tooltip tooltip-left ${revertingId === adoption.id ? 'loading' : ''}`}
                                data-tooltip="Reverter"
                                onClick={() => onPressRevert(adoption)}
                                disabled={revertingId === adoption.id}
                              >
                                <i className="fas fa-undo" />
                              </a>
                            )
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ) : (
              <ErrorBox
                error={{
                  icon: 'fas fa-heart fa-2x',
                  title: 'Nenhuma adoção',
                  message: 'Não há histórico de adoções',
                }}
              />
            )
        }
      </Card.Body>
    </Card.Base>
  )
}

AdoptionsCard.propTypes = {
  adoptions: PropTypes.arrayOf(Object),
  onReverted: PropTypes.func.isRequired,
}

AdoptionsCard.defaultProps = {
  adoptions: [],
}
