import api from './api'

export default {
  list(params = {}) {
    return api.get('/people', { params })
  },

  show(id) {
    return api.get(`/people/${id}`)
  },

  animals(id, params = {}) {
    return api.get(`/people/${id}/animals`, { params })
  },

  castrations(id, params = {}) {
    return api.get(`/people/${id}/castrations`, { params })
  },

  donations(id, params = {}) {
    return api.get(`/people/${id}/donations`, { params })
  },

  adoptions(id, params = {}) {
    return api.get(`/people/${id}/adoptions`, { params })
  },

  update(id, body) {
    return api.put(`/people/${id}`, body)
  },

  setOwner(id) {
    return api.post(`/people/${id}/owner`)
  },

  unsetOwner(id) {
    return api.delete(`/people/${id}/owner`)
  },

  activateAccount(id) {
    return api.post(`/people/${id}/activate-account`)
  },
}
