import {
  ErrorBox, Flexbox, LoadManager, PageHeader, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import people from '@/services/api/people'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import dateMask from '@/lib/masks/date'
import useUser from '@/hooks/useUser'
import { setUser } from '@/reducers/user'
import AdoptionsCard from './components/AdoptionsCard'
import AnimalsCard from './components/AnimalsCard'
import UserCard from './components/UserCard'
import CastrationsCard from './components/CastrationsCard'
import DonationsCard from './components/DonationsCard'

function Show({ title }) {
  const { state: { user }, dispatch } = useUser()
  usePageTitle({ title })
  const { id } = useParams()
  const {
    data, setData, loading, error,
  } = useAxiosRequest(people.show, id)

  useEffect(() => {
    dispatch(setUser(data))
  }, [data, dispatch])

  function onUpdate(values) {
    setData({ ...data, ...values })
  }

  function onActivate() {
    setData({ ...data, deleted: false })
  }

  if (loading === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (loading === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader icon="fas fa-user" title="Usuário" />
      {
        Boolean(user?.partnerBlacklist) && (
          <>
            <div className="toast toast-error mt-2">
              <span className="h6">
                Este usuário foi <b>bloqueado</b> por
                &nbsp;{user?.partnerBlacklist?.author?.fullName} em
                &nbsp;{dateMask(user?.partnerBlacklist?.createdAt, { hour: '2-digit', minute: '2-digit' })}
              </span>
              <div className="pre">{user?.partnerBlacklist?.description}</div>
              <div className="pre mt-2 text-small">{user?.partnerBlacklist?.info}</div>
            </div>
            <div className="divider" />
          </>
        )
      }
      <LoadManager loading={loading} error={error}>
        {
          Boolean(user) && (
            <>
              <Flexbox.Columns>
                <Flexbox.Column className="col-5 col-md-12 my-2">
                  <UserCard person={user} onUpdate={onUpdate} onActivate={onActivate} />
                </Flexbox.Column>
                <Flexbox.Column className="col-7 col-md-12 my-2">
                  <AnimalsCard lastAnimals={user?.lastAnimals} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <CastrationsCard lastCastrations={user?.lastCastrations} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <DonationsCard lastDonations={user?.lastDonations} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <AdoptionsCard lastAdoptions={user?.lastAdoptions} />
                </Flexbox.Column>
              </Flexbox.Columns>
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default Show
