/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Avatar, Card, Flexbox } from '@/components'
import documentNumberMask from '@/lib/masks/documentNumber'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import phoneNumberMask from '@/lib/masks/phoneNumber'
import EditUser from './Edit'
import { LogoGovBr } from './styles'
import Activate from './Activate'

export default function UserCard({ person, onUpdate, onActivate }) {
  return (
    <Card.Base>
      <Card.Body>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <Avatar
              avatarSize="lg"
              name={person?.fullName}
              photoURL={person?.photoURL}
              alt="Imagem de usuário"
            />
          </Flexbox.Column>
          <Flexbox.Column className="text-ellipsis">
            <div className="h5 text-ellipsis">{person?.fullName}</div>
            <div className="text-ellipsis">{person?.email}</div>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>Telefone:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {phoneNumberMask(person?.phoneNumber) || 'Não informado'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>Documento:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {documentNumberMask(person?.documentNumber) || 'Não informado'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>Data de nascimento:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {person?.birthdayDate ? dateMask(person?.birthdayDate) : 'Não informada'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>Administrador:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {person?.isOwner ? 'Sim' : 'Não'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>Criado em:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {dateMask(person?.createdAt, { hour: '2-digit', minute: '2-digit' })}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>E-mail verificado:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {person?.emailVerified ? 'Sim' : 'Não'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <b>Endereço</b>
        <div>{person?.address?.formattedAddress || 'Não informado'}</div>

        {
          Boolean(person?.personGovBr) && (
            <>
              <div className="divider" />
              <div className="d-flex align-center">
                Dados do&nbsp;<LogoGovBr alt="Logo gov.br" />
              </div>
              <div className="mb-2 pb-2">
                <small>&nbsp;(atualizado em {dateMask(person?.personGovBr?.updatedAt, 'DD/MM/YYYY')})</small>
              </div>
              <div className="mb-1" />
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>E-mail</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {person?.personGovBr?.email}
                  {
                    person?.personGovBr?.email_verified
                    ? <i className="fas fa-certificate text-success mt-1 ml-1 tooltip tooltip-left" data-tooltip="Verificado" />
                    : <i className="fas fa-exclamation-circle text-error mt-1 ml-1 tooltip tooltip-left" data-tooltip="Não verificado" />
                  }
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Fone</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {phoneNumberMask(person?.personGovBr?.phone_number)}
                  {
                    person?.personGovBr?.phone_number_verified
                    ? <i className="fas fa-certificate text-success mt-1 ml-1 tooltip tooltip-left" data-tooltip="Verificado" />
                    : <i className="fas fa-exclamation-circle text-error mt-1 ml-1 tooltip tooltip-left" data-tooltip="Não verificado" />
                  }
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>CPF</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {documentNumberMask(person?.personGovBr?.sub)}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Nome</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {person?.personGovBr?.name}
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="divider text-center" data-content="Selos" />
              {
                (person?.personGovBr?.selos ?? []).map((selo) => (
                  <React.Fragment key={selo.id}>
                    <Flexbox.Columns className="align-center mb-1">
                      <Flexbox.Column className="col-auto">
                        <i className="fas fa-fw fa-lg fa-star" />
                      </Flexbox.Column>
                      <Flexbox.Column>
                        <div>{selo.descricao}</div>
                        <div><small>em {dateMask(selo.dataAtualizacao, 'DD/MM/YYYY')}</small></div>
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </React.Fragment>
                ))
              }
              <div className="divider text-center" data-content="Níveis da conta" />
              {
                (person?.personGovBr?.niveis ?? []).map((nivel) => (
                  <React.Fragment key={nivel.id}>
                    <Flexbox.Columns className="align-center mb-1">
                      <Flexbox.Column className="col-auto">
                        <div className={`nivel-container flex-centered nivel-container-${nivel.id}`}>
                          {Number(nivel.id) === 1 && <i className="far fa-fw fa-star" />}
                          {Number(nivel.id) === 2 && <i className="fas fa-fw fa-star-half-alt" />}
                          {Number(nivel.id) === 3 && <i className="fas fa-fw fa-star" />}
                        </div>
                      </Flexbox.Column>
                      <Flexbox.Column>
                        <div>{nivel.descricao}</div>
                        <div><small>em {dateMask(nivel.dataAtualizacao, 'DD/MM/YYYY')}</small></div>
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </React.Fragment>
                ))
              }
            </>
          )
        }
      </Card.Body>
      <Card.Footer>
        <div className="divider" />
        <div className="mt-2">
          <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
            <Activate onActivate={onActivate} person={person} />
            <EditUser onUpdate={onUpdate} person={person} />
          </div>
        </div>
      </Card.Footer>
    </Card.Base>
  )
}

UserCard.propTypes = {
  person: PropTypes.objectOf(Object).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
}
